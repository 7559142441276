import React from 'react'
import Container from '../components/container'
import SEO from '../components/seo'
import { Row, Col, Typography, Space } from 'antd'
import { Link } from 'gatsby'
const { Text, Title } = Typography

const NotFoundPage = () => (
  <Container>
    <SEO title="404: Not found" />
    <Row justify="center" align="middle">
      <Space direction="vertical" style={{ textAlign: 'center' }}>
        <Title level={2}>404 Not Found</Title>
        <Text>
          How did you get here? Silly sausage <span role="img">🤓</span>,{' '}
          <Link to="/">head back to the main page</Link>
        </Text>
      </Space>
    </Row>
  </Container>
)

export default NotFoundPage
